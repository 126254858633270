// NOTE: housing_worlds とは別のAPI、モデルだけど将来的に housing_worlds に統一する
//       housing_worlds = アウトゲームでのみ使う情報
//       housing_scene = インゲーム側でも使う情報

import { z } from 'zod'
import { profileData } from './profiles'

// scene = 各ルーム
export const roomData = z.object({
  id: z.number(),
  userId: z.number(),
  worldId: z.string(),
  sceneJson: z.string(), // json string
  name: z.string().nullable(),
  introduction: z.string().nullable(),
  platformCode: z.string(),
  presetHousingHomeId: z.number().nullable(),
  presetHousingHome: z.object({
    id: z.number(),
    platformCode: z.string(),
    name: z.string(),
    path: z.string(),
    filename: z.string(),
    order: z.number(),
    tutorialUse: z.boolean(),
    createdAt: z.union([z.instanceof(Date), z.string()]), // date
    updatedAt: z.union([z.instanceof(Date), z.string()]), // date
  }),
  thumbnail: z
    .object({
      url: z.string().nullable(),
    })
    .nullable(),
  published: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})
export const roomDataWithProfile = roomData.extend({
  profile: profileData.nullable(),
})

export type RoomData = z.infer<typeof roomData>
export type RoomDataWithProfileData = z.infer<typeof roomDataWithProfile>

// get

export const getRoomRequest = z.object({
  worldId: z.string(),
})

export type GetRoomRequest = z.infer<typeof getRoomRequest>

export const getRoomResponse = z.object({
  housingScene: roomData,
})

export type GetRoomResponse = z.infer<typeof getRoomResponse>

export const getMyRoomRequest = z.object({
  worldId: z.string(),
})

export type GetMyRoomRequest = z.infer<typeof getMyRoomRequest>

export const getMyRoomResponse = z.object({
  housingScene: roomData,
})

export type GetMyRoomResponse = z.infer<typeof getMyRoomResponse>

export const getRoomsRequest = z.object({
  userId: z.number(),
})

export type GetRoomsRequest = z.infer<typeof getRoomsRequest>

export const getRoomsResponse = z.object({
  housingScenes: z.array(roomData),
})

export type GetRoomsResponse = z.infer<typeof getRoomsResponse>

// patch

export const patchRoomRequest = z.object({
  vketId: z.string(),
  room: roomData,
})

export type PatchRoomRequest = z.infer<typeof patchRoomRequest>

export const patchRoomResponse = z.object({
  housingScene: roomData,
})

export type PatchRoomResponse = z.infer<typeof patchRoomResponse>
