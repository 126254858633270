import type { InjectionKey, DeepReadonly } from 'vue'

// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'

// modules
import { isValueOf } from '@/utils/zod'

// models
import {
  getRoomResponse,
  getRoomsResponse,
  patchRoomResponse,
  RoomData,
} from '@/models/housing'
import { ProfileData } from '@/models/profiles'

// composables
import { useAuth } from '@/composables/useAuth'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

/**
 * heliodor.js内で宣言されている変数を参照している。
 * Reference: public/data/heliodor_front.js
 */
declare const hel_onloaded_add: Function // eslint-disable-line camelcase
declare const hel_canvas_SetLayoutShow: Function // eslint-disable-line camelcase
declare const hel_canvas_ToggleChange: Function // eslint-disable-line camelcase

/** ハウジングルーム管理 */
export const useHousing = () => {
  const repositoryFactory = useRepositoryFactory()
  const housingRepository = repositoryFactory.get('housing')

  type HousingState = {
    currentRoom: RoomData | null // 詳細表示しているルーム
    isLoadedHousing: boolean
  }

  const { logout } = useAuth()

  const state = useState<HousingState>('housing-state', () => ({
    currentRoom: null, // 詳細表示しているルーム
    isLoadedHousing: false,
  }))

  // 初回ローディング後の処理を追加
  const onloadedAdd = (callnack: Function) => {
    /* eslint-disable */
    // prettier-ignore
    if ((typeof hel_onloaded_add !== 'undefined') && hel_onloaded_add) {
      hel_onloaded_add(() => {
        callnack();
        state.value.isLoadedHousing = true
      })
    }
    /* eslint-disable */
  }

  /** ハウジングのUI初期表示を表示切替 */
  const setHousingUI = (show: boolean) => {
    hel_canvas_SetLayoutShow('HUD', show)
    hel_canvas_SetLayoutShow('HUD_po', show)
    hel_canvas_SetLayoutShow('Housing', show)
    hel_canvas_ToggleChange('Toggle_HousingMode')
    hel_canvas_ToggleChange('Toggle_HousingTutorial')
  }

  const initHousing = () => {
    state.value.currentRoom = null
  }

  /**
   * globalにマイルームのworldIdをセットする
   * @params vketId
   * */
  const setHousingWorldId = (vketId: string) => {
    g_WorldID = `MyRoom-${vketId}`
  }

  const getMyRooms = async (): Promise<RoomData[]> => {
    try {
      const response = await housingRepository.get.getMyRooms()

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getRoomsResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.currentRoom = response.housingScenes[0] || null

      return response.housingScenes
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        logout()
        throw new Error('Invalid User')
      }
      throw e
    }
  }

  const getRoom = async (worldId: string): Promise<RoomData | null> => {
    try {
      const response = await housingRepository.get.getRoom({
        worldId,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getRoomResponse, response)) {
        console.error('An API response is different.')
      }

      if (!response.housingScene) {
        throw new Error('Failed to get Room')
      }

      state.value.currentRoom = response.housingScene

      return response.housingScene
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        logout()
        throw new Error('Invalid User')
      }
      throw e
    }
  }

  const getMyRoom = async (worldId: string): Promise<RoomData | null> => {
    try {
      const response = await housingRepository.get.getMyRoom({
        worldId,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getRoomResponse, response)) {
        console.error('An API response is different.')
      }

      if (!response.housingScene) {
        throw new Error('Failed to get Room')
      }

      state.value.currentRoom = response.housingScene

      return response.housingScene
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        logout()
        throw new Error('Invalid User')
      }
      throw e
    }
  }

  const patchMyRoom = async (
    room: RoomData,
    vketId: string
  ): Promise<RoomData | null> => {
    try {
      const response = await housingRepository.patch.patchMyRoom({
        vketId,
        room,
      })

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(patchRoomResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.currentRoom = response.housingScene

      return response.housingScene
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        logout()
        throw new Error('Invalid User')
      }
      throw e
    }
  }

  /**
   * myvketのマイルームのインゲームURL取得
   */
  const getRoomURL = (worldId: string) => {
    return `/myroom/maker/?worldid=${worldId}`
  }

  // 直近で取得しているルームのURL
  const currentRoomURL = computed(() =>
    state.value.currentRoom ? getRoomURL(state.value.currentRoom?.worldId) : ''
  )

  const housingAndProfileMerge = (
    roomList: DeepReadonly<RoomData[]>,
    profileList: DeepReadonly<ProfileData[]>
  ) => {
    return roomList.map((room) => {
      const profile = profileList.find(
        // Note: roomのuserにvketIdがないのでuserIdで判別
        (profile) => profile.userId === room.userId
      )
      return {
        ...room,
        profile: profile || null,
      }
    })
  }

  return {
    state: readonly(state),
    initHousing,
    setHousingWorldId,
    patchMyRoom,
    getMyRooms,
    getRoom,
    getMyRoom,
    getRoomURL,
    currentRoomURL,
    housingAndProfileMerge,
    // heliodor housing管理
    onloadedAdd,
    setHousingUI,
  }
}

export type HousingComposable = ReturnType<typeof useHousing>

export const housingInjectionKey: InjectionKey<HousingComposable> =
  Symbol('housing-composable')
